import React from "react";
import PageWrapper from "../components/PageWrapper";
import Rte from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import Contenteye from "../sections/eyebrow/eyecontent.js";
import EyeFaq from "../sections/eyebrow/eyefaq.js";
import Head from "../components/head"
import Testimonial from "../sections/index/indexreviews.js";
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/eyebrow.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";

const Eyebrow = () => {
  return (
    <>
    <Head title="Eyebrow Lift Treatment UK" image="/images/dermamina-home.jpg"  description="Experts in Eyebrow Lift treatments London" keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>
<PageWrapper headerDark>
<HeroComponent
    itext='<span style="color: #1a1a1a;">Eyebrow </span> <span style="color: #00aec7;">Lift</span>'
    secondText=" Enhance & Rejuvenate your appearance"
    videoAlt="Eyebrow Lift"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Eyebro Lift"
  resultstext="Up to months"
  resultsalt="Eyebro Lift results"
  costdata="£340"
  costalt="Eyebro Lift Cost"
  timedata="10 Minutes"
  timealt="Eyebro Lift Duration Time"
  workdata="Immediately"
  workalt="Eyebro Lift downtime work"
  paindata="None"
  painalt="Eyebro Lift Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Testimonial />
<Contenteye />
<EyeFaq />        
<Clinic /> 
<Saveface />
<Rte />
</PageWrapper>

    </>
  );
};
export default Eyebrow;
